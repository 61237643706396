<template>
  <div id="tracking">
    <v-flex xs12 class="box mt-16">
      <v-btn v-if="!isMobile" icon color="black" @click="back()" class="mt-12 ml-8">
        <v-icon medium>mdi-arrow-left</v-icon> {{ $t('back')}}
      </v-btn>

      <v-card
        class="mx-auto mt-3 mb-5"
        elevation="0"
        max-width="100%"
      >
        <v-card-title class="text-1">{{ article.judul }}</v-card-title>
        
        <v-img
          :height="isMobile ? 500 : 1000"
          :src="article.icon"
        ></v-img>
    
        <v-card-text class="text-2">
          <div v-html="article.konten"></div>
        </v-card-text>
  
      </v-card>
    </v-flex>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/axios'

  export default {
    name: 'ArticleView',
    computed: {
      ...mapState([
        'isMobile'
      ])
    },
    data () {
      return {
        article: {}
      }
    },
    methods: {
       getArticle () {
        this.$store.commit('setIsLoading', true)
        axios.get('/api/v1/pages/artikel/' + this.$route.params.slug)
          .then(response => {
            this.article = response.data.data
            this.setMetaInfo(null, this.article.judul)
          })
          .catch(error => {
            this.errorHandling(error)
          })
          .finally(() => {
            this.$store.commit('setIsLoading', false)
          })
      }
    },
    mounted () {
      if (this.$route.params.slug) {
        this.getArticle()
      }

      this.$store.commit('setIsHome', false)
      this.$store.commit('setIsMemberPage', true)
      this.$store.commit('setIsLoginPage', false)
      this.$store.commit('setActivePage', this.$t('article.title'))
    }
  }
</script>

<style scoped>
  .box {
    background-color: #FFFFFF;
    color: #000000 !important;
    padding: 20px;
    margin-bottom: 2px;
    font-size: 0.8rem !important;
    font-style: normal !important;
  }

  .text-1 {
    font-size: 1.4rem;
    color: #000000 !important;
    font-weight: 600;
    word-break: normal;
    line-height: 2rem;
    padding: 16px 0 !important;
  }

  .text-1-w {
    font-size: 1.7rem;
    color: #000000 !important;
    font-weight: 600;
    word-break: normal;
    line-height: 2rem;
    padding: 0 !important;
  }

  .text-2-w {
    text-align: justify;
    text-justify: inter-word;
    font-size: 0.9rem;
    color: #000 !important;
  }

  .text-2 {
    text-align: justify;
    text-justify: inter-word;
    padding: 16px 0 !important;
  }
</style>
